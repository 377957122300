import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi, I'm <span className="purple">Mosabbir Alam Noman </span>
            from <span className="purple"> Dhaka, Bangladesh</span>
            <br />I am a Enterprenuer having a <a href="https://www.northsouth.edu/academic/sbe/acc-fin/">Bachelors degree in Business Administration from <b className="purple">North South University, Bangladesh.</b></a>.
            <br />
            <br />
            <br />
            Apart from accounting, these are some other activities that I enjoy:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Sports ( Football, Cricket, Badminton, Chess)
            </li>
            <li className="about-activity">
              <ImPointRight /> Traveling
            </li>
            <li className="about-activity">
              <ImPointRight /> Listening to Music
            </li>
          </ul>

          <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            “Every problem is a gift—without problems we would not grow.”{" "}
          </p>
          <footer className="blockquote-footer"> Anthony Robbins</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
