import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiPostman,
  SiHeroku,
  SiGithub
} from "react-icons/si";
import {
    DiAtlassian,
    DiAws,
    DiDatabase,
    DiDocker,
    DiGit,
    DiGoogleAnalytics,
    DiMysql,
    DiPostgresql,
    DiStackoverflow,
    DiTerminal,
    DiUbuntu,
    DiVisualstudio,
    DiWindows
} from "react-icons/di";
import { FaChartBar, FaMediumM, FaShopify, FaSkype, FaTwitter, FaWordpressSimple } from "react-icons/fa";


function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
      <FaShopify />
      </Col>
        <Col xs={4} md={2} className="tech-icons">
     
        <DiGoogleAnalytics />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
        <FaWordpressSimple />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <DiWindows />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
        <FaSkype />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
        <FaTwitter />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
        <FaMediumM />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
        <FaChartBar />
        </Col>
        
    </Row>
  );
}

export default Toolstack;
