import React from "react";
import { Col, Row } from "react-bootstrap";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFileWord } from "react-icons/fa";
import { SiMicrosoftaccess } from "react-icons/si";
import { FaLinkedin } from "react-icons/fa";
import { SiAdobephotoshop } from "react-icons/si";
import { SiAdobeaftereffects } from "react-icons/si";
import { SiXero } from "react-icons/si";
// import { SiQuickbooks } from "react-icons/si";
import { SiMicrosoftoffice } from "react-icons/si";

import {
   
    DiReact,
    DiNodejs,
    DiBootstrap,
    DiPython,
    DiDjango, DiJava, DiRasberryPi
} from "react-icons/di";
import {SiJupyter} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <RiFileExcel2Line />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <FaRegFileWord />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftaccess />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaLinkedin />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAdobephotoshop />
      </Col>
        <Col xs={4} md={2} className="tech-icons">
            <SiAdobeaftereffects />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            <SiXero />
        </Col>
        <Col xs={4} md={2} className="tech-icons">
            {/* <SiQuickbooks /> */}
            <SiMicrosoftoffice/>
        </Col>
     
    </Row>
  );
}

export default Techstack;
