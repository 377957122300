import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
// import BlogCard from "./BlogsCards";
import Particle from "../Particle";

// import leaf from "../../Assets/Projects/leaf.png";
// import emotion from "../../Assets/Projects/emotion.jpeg";
// import editor from "../../Assets/Projects/codeEditor.png";
// import chatify from "../../Assets/Projects/chatify.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import algo from "../../Assets/Projects/algo.png";
// import plant from "../../Assets/Projects/plant.jpeg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works and Theory</strong>
        </h1>

        <div className="view__point">

        <p >
        A dynamic and versatile under-grad from North South University thriving for personal development and growth. My passion for excellence is reflected in a diverse skill set covering digital marketing, photography, and video editing. Proficient in English, excellence in effective communication and building connections is evident. Leveraging social media for strategic brand promotion and ensuring meticulous financial management in accounting, creativity and fiscal responsibility are brought to every project.

Expertise extends to cutting-edge technologies like Artificial Intelligence, fostering collaborative environments through a leadership mindset. With a strong foundation in project management and event planning along with proficiency in Meta Ad Marketing and Microsoft Office, Word and Excel. A results-driven approach is ready to contribute to projects demanding excellence and innovation. Let's collaborate and bring ideas to life!
        </p>
        </div>
        {/* <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p> */}

        {/* <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              //imgPath={}
              //isBlog={false}
              //title=""
              //description=""
              //link="https://github.com/beebus/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              //imgPath={}
              //isBlog={false}
              //title=""
              //description=""
              //link="https://github.com/beebus/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              //imgPath={}
              //isBlog={false}
              //title=""
              //description=""
              //link="https://github.com/beebus/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              //imgPath={}
              //isBlog={false}
              //title=""
              //description=""
              //link="https://github.com/beebus/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              //imgPath={}
              //isBlog={false}
              //title=""
              //description=""
              //link="https://github.com/beebus/"
            />
          </Col>
        </Row> */}
      </Container>
    </Container>
  );
}

export default Projects;
